import styled from 'styled-components';

const MetaWrapper = styled.div`

  font-size: 0.8rem;
  padding-left: 0.3rem;
  p {
    margin: 0;
    line-height: 1rem;
  }
  .category {
    a {
      color: var(--color-pallate5);
      font-weight: bold;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  .tag {
    margin-right: 0.4rem;
    font-weight: bold;
    color: var(--color-pallate3) !important;
    text-decolration: none;
    :hover {
      text-decoration: underline;
    }
  }
  .tag::before {
    content: "#"
  }

  .date {
    margin-left: 0.4rem;
    padding-left: 0.4rem;
    border-left: 1px solid #ccc;
    color: var(--color-pallate4);
  }
  @media (max-width: 768px) {
    font-size: 1rem;  
    p.tags {
      line-height: 1.1rem;
      margin-top: 0.75rem;
    }
    .tag {
      margin-right: 0.6rem;
    }
  }

`;

export default MetaWrapper;
